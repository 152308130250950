import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import OriginalBlogPostTemplate from '../../../plugins/gatsby-theme-fgs-blog/src/templates/blog-post';

const BlogPostTemplate = props => {
  const { blogPost } = props.pageContext;
  const siteTitleSuffix = ' | React Freelancer Switzerland';
  const siteTitleMaxLength = 65;
  const siteTitle =
    `${blogPost.frontmatter.title}${siteTitleSuffix}`.length <=
    siteTitleMaxLength
      ? `${blogPost.frontmatter.title}${siteTitleSuffix}`
      : blogPost.frontmatter.title;
  return (
    <Layout displayReadingProgressBar={true}>
      <SEO
        title={siteTitle}
        description={blogPost.parent.excerpt}
        // image={`${data.site.siteMetadata.siteUrl}/${blogPost.frontmatter.featuredImage.publicURL}`}
        image={blogPost.frontmatter.featuredImage.publicURL}
        meta={[
          {
            property: `og:type`,
            content: `article`,
          },
        ]}
      />
      <div className="container">
        <article>
          <header className="post-full-header">
            <div className="post-full-meta">
              <time className="post-full-meta-date">
                {blogPost.frontmatter.date}
              </time>
            </div>
            <h1 className="post-full-title">{blogPost.frontmatter.title}</h1>
          </header>
          {blogPost.frontmatter.featuredImage ? (
            <figure className="post-feature-image">
              {blogPost.frontmatter.featuredImage && (
                <img src={blogPost.frontmatter.featuredImage.publicURL} />
              )}
            </figure>
          ) : null}
          <section className="post-full-content">
            <section className="content-body">
              <OriginalBlogPostTemplate {...props} />
            </section>
          </section>
        </article>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;
