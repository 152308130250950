import React from 'react';

import florianGyger from '../../assets/images/florian-gyger.jpg';

const Bio = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '1rem',
        marginBottom: '2rem',
      }}
    >
      <img
        style={{
          width: 60,
        }}
        className="img-fluid rounded-circle"
        src={florianGyger}
        alt="Florian Gyger"
      />
      <div
        style={{
          marginLeft: '1rem',
          color: '#999',
        }}
      >
        <p
          style={{
            marginBottom: 0,
            fontSize: '1rem',
            lineHeight: '1.25rem',
          }}
        >
          <b>
            Written by{' '}
            <a
              href="mailto:florian.gyger@floriangyger.ch"
              title="Write an email to Florian Gyger"
            >
              Florian Gyger
            </a>
          </b>
          <br />
          Florian Gyger develops web and mobile apps using React, React Native,
          Gatsby and AWS Amplify.
        </p>
      </div>
    </div>
  );
};

export default Bio;
