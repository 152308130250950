import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { preToCodeBlock } from 'mdx-utils';
import { MDXProvider } from '@mdx-js/react';
import Bio from '../components/bio';
import Code from '../components/code';

const components = {
  pre: preProps => {
    const props = preToCodeBlock(preProps);
    if (props) {
      return <Code {...props} />;
    }
    return <pre {...preProps} />;
  },
};

const BlogPostTemplate = props => {
  const { blogPost } = props.pageContext;
  return (
    <>
      <Bio />
      <MDXProvider components={components}>
        <MDXRenderer>{blogPost.parent.body}</MDXRenderer>
      </MDXProvider>
    </>
  );
};

export default BlogPostTemplate;
